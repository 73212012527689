import React, { Component } from 'react';
import { Flex, Box, Text, Heading } from "rebass";
import styled from "styled-components";
import { FormComponent, FormContainer } from "react-authorize-net";
import axios from "axios";
import qs from "qs";
import Loader from 'react-loader-spinner';

//let clientKey = "735NyLm442DbVuT7Pfkd4535B3PZ8Wv9jkKhZWnwgvyY8hs6YPJJtKQ6YM4z24Hu";
let clientKey = "23FLc8Lg3pDAaHCKd73n6MsUs9gu5v9ekq76JBZW32L95EJg65cYPpReYMNTYabq";
//let apiLoginId = "9s24DCvf"; Sandbox
let apiLoginId = "87xRFc6w";

type State = {
  status: "paid" | "unpaid" | "waiting" | ["failure", string[]];
};

const Button = styled.button({
  "&:hover": { cursor: "pointer" },
  padding: "10px",
  backgroundColor: "white",
  border: "2px solid black",
  fontWeight: 600,
  borderRadius: "2px"
});

const ErrorComponent = (props: {
  errors: string[];
  onBackButtonClick: () => void;
}) => (
  <div>
    <Text fontSize={3} fontWeight={"500"} mb={3}>
      Failed to process payment:
    </Text>
    {props.errors.map(error => (
      <Text py={2}>{error}</Text>
    ))}
    <Button style={{color:"black"}} onClick={props.onBackButtonClick}>Go Back</Button>
  </div>
);

const WaitComponent = () => (
  <div>
    <Loader type="Oval" color="white" width="100" height="100"/>
    <Text fontSize={3} fontWeight={"500"} mb={3}>
      Waiting on response from Authorize.Net
    </Text>
  </div>
);

const Header = props => (
  <Flex py={4}>
    <Heading>Authorize.Net payment</Heading>
  </Flex>
);

class Payment extends Component<{}, State> {
  constructor(props){
    super(props);
    let {address} = props.getStore();
    let {flags} = props.getStore();
    let cost = 0;
    flags.map((val, idx)=>{
      if(flags[idx].shiptype==="0"||flags[idx].shiptype==="2"||flags[idx].shiptype==="3"||flags[idx].shiptype==="4"){
        cost = cost + 35;
      }else if(flags[idx].shiptype==="1"){
        cost = cost + 20;
      }else{
        throw new Error("Invalid shiptype, please try entering you information again");
      }
      return "";
    })

    let extship = 0;

    var uniqship = {}
    flags.map((val, idx)=>{
      if(parseInt(flags[idx].shiptype)===4){
        if(flags[idx].flagaddress in uniqship){
          uniqship[flags[idx].flagaddress] = uniqship[flags[idx].flagaddress] + 1
        }else{
          uniqship[flags[idx].flagaddress] = 1
        }
      }
      return "";
    })

    flags.map((val, idx)=>{
      if(parseInt(flags[idx].shiptype)===3){
        if(address in uniqship){
          uniqship[address] = uniqship[address] + 1
        }else{
          uniqship[address] = 1
        }
      }
      return "";
    })

    function addZeroes( num2 ) {
       var num = Number(num2);
       if (String(num).split(".").length < 2 || String(num).split(".")[1].length<=2 ){
           num = num.toFixed(2);
       }
       return num;
    }

    for(var key in uniqship){
      var count = uniqship[key]
      extship = extship + (Math.ceil(count/2)*7.5)
    }
    cost = cost + extship
    cost = addZeroes(cost)

    this.state = {status:"unpaid",sampleStore:props.getStore(),orderid:null,cost:cost,transactionID:0,error:""}
  }

  componentDidMount(){
    var orderid = null
    let self = this;
    axios.post("https://fohapi.groupeleven.com/OnlineInsert/",qs.stringify(this.state.sampleStore)
    ).then(function(response){
      orderid = response.data.orderid
      self.setState({orderid:orderid});
    }).catch(function(error){
      alert(error);
    });
  }

  onErrorHandler = (response: any) => {
    this.setState({
      status: ["failure", response.messages.message.map(err => err.text)]
    });
  };

  onSuccessHandler = (response: any) => {
    let success = false;
    let error = "";
    let transactionID = 0;
    let self = this;
    axios.post("https://fohapi.groupeleven.com/Paid/",qs.stringify({orderid:this.state.orderid,dataDescriptor:response["opaqueData"]["dataDescriptor"],nonce:response["opaqueData"]["dataValue"]})
    ).then(function(response){
      success = response.data.success
      transactionID = response.data.transactionid
      error = response.data.error
      if(success===true){
        self.setState({status:"paid"});
        self.setState({transactionID:transactionID});
      }else{
        self.setState({status:"unpaid"});
        self.setState({status:["failure",[error]]});
      }
    });
    self.setState({status:"waiting"});
  };

  render() {
    return (
      <Box className="App" p={3}>
        <Header />
        {this.state.status === "paid" ? (
          <Text fontWeight={"500"} fontSize={3} mb={4}>
            Thank you for Supporting the Field of Heroes
            <br/>
            Your transaction id is {this.state.transactionID} and you will recieve an email shortly from authorize.net.
          </Text>
        ) : this.state.status === "unpaid" ? (
          <FormContainer
            environment="production"
            onError={this.onErrorHandler}
            onSuccess={this.onSuccessHandler}
            amount={this.state.cost}
            component={FormComponent}
            clientKey={clientKey}
            apiLoginId={apiLoginId}
          />
        ) : this.state.status[0] === "failure" ? (
          <ErrorComponent
            onBackButtonClick={() => this.setState({ status: "unpaid" })}
            errors={this.state.status[1]}
          />
        ) : this.state.status === "waiting" ? (
          <WaitComponent/>
        ) : null}
      </Box>
    );
  }
}

export default Payment;