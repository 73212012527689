import React from 'react';
import FoHFoot from './Foot.js';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

export class videos extends React.Component {
  render() {
    return (
      <div className="App">
        <content className="App-content">
          <Grid container justify="center">
            <Typography variant="h3" align="center" color="inherit">Page under construction</Typography>
          </Grid>
          <div className="divider"/>
          <FoHFoot/>
        </content>
      </div>
    );
  }
}

export default videos;