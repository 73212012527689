import React from 'react';
import Circle from './img/bluetag/circle.png'
import Category from './Categories.js'
import FlagStates from './FlagStates.js'
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import info from "./img/info.svg"
import Popover from "@material-ui/core/Popover";
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing.unit,
  },
});

export class Flag extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
      firstname: props.getStore().firstname,
      lastname: props.getStore().lastname,
      organization: props.getStore().organization,
      address: props.getStore().address,
      city: props.getStore().city,
      state: props.getStore().state,
      zip: props.getStore().zip,
      phone: props.getStore().phone,
      email: props.getStore().email,
      notes:props.getStore().notes,
      flags:props.getStore().flags,
    }

    this.handleheroprefixchanged = this.handleheroprefixchanged.bind(this);
    this.handleherofirstnamechanged = this.handleherofirstnamechanged.bind(this);
    this.handleherolastnamechanged = this.handleherolastnamechanged.bind(this);
    this.handleherosuffixchanged = this.handleherosuffixchanged.bind(this);
    this.handleherootherchanged = this.handleherootherchanged.bind(this);
    this.handleherocategorychanged = this.handleherocategorychanged.bind(this);
    this.handleherotributechanged = this.handleherotributechanged.bind(this);
    this.handleheronoteschanged = this.handleheronoteschanged.bind(this);
    this.handleRecipientChanged = this.handleRecipientChanged.bind(this);
    this.addAnotherFlag = this.addAnotherFlag.bind(this);
    this.deleteFlag = this.deleteFlag.bind(this);
    this.shiptypechanged = this.shiptypechanged.bind(this);
    this.handleflagaddressChanged = this.handleflagaddressChanged.bind(this);
    this.handleflagcityChanged = this.handleflagcityChanged.bind(this);
    this.handleflagstateChanged = this.handleflagstateChanged.bind(this);
    this.handleflagzipChanged = this.handleflagzipChanged.bind(this);
    this.handlebehalfChanged = this.handlebehalfChanged.bind(this);
  }

  handleheroprefixchanged(event){
    let idx = event.target.id
    const newFlags = this.state.flags.map((flag, sidx) =>
    {
      if(idx !== "inputheroprefix-"+sidx) return flag;
      return {...flag,heroprefix:event.target.value}
    })
    this.props.updateStore({
      flags: newFlags
    })
    this.setState({flags:newFlags})
  }

  handleherofirstnamechanged(event){
    let idx = event.target.id
    const newFlags = this.state.flags.map((flag, sidx) =>
    {
      if(idx !== "inputherofirstname-"+sidx) return flag;
      return {...flag,herofirstname:event.target.value}
    })
    this.props.updateStore({
      flags: newFlags
    })
    this.setState({flags:newFlags})
  }

  handleherolastnamechanged(event){
    let idx = event.target.id
    const newFlags = this.state.flags.map((flag, sidx) =>
    {
      if(idx !== "inputherolastname-"+sidx) return flag;
      return {...flag,herolastname:event.target.value}
    })
    this.props.updateStore({
      flags: newFlags
    })
    this.setState({flags:newFlags})
  }

  handleherosuffixchanged(event){
    let idx = event.target.id
    const newFlags = this.state.flags.map((flag, sidx) =>
    {
      if(idx !== "inputherosuffix-"+sidx) return flag;
      return {...flag,herosuffix:event.target.value}
    })
    this.props.updateStore({
      flags: newFlags
    })
    this.setState({flags:newFlags})
  }

  handleherootherchanged(event){
    let idx = event.target.id
    const newFlags = this.state.flags.map((flag, sidx) =>
    {
      if(idx !== "inputheroother-"+sidx) return flag;
      return {...flag,heroother:event.target.value}
    })
    this.props.updateStore({
      flags: newFlags
    })
    this.setState({flags:newFlags})
  }

  handleherocategorychanged(event){
    let idx = event.target.id
    const newFlags = this.state.flags.map((flag, sidx) =>
    {
      if(idx !== "inputherocategory-"+sidx) return flag;
      return {...flag,herocategory:event.target.value}
    })
    this.props.updateStore({
      flags: newFlags
    })
    this.setState({flags:newFlags})
  }

  handleherotributechanged(event){
    let idx = event.target.id
    const newFlags = this.state.flags.map((flag, sidx) =>
    {
      if(idx !== "inputherotribute-"+sidx) return flag;
      return {...flag,herotribute:event.target.value}
    })
    this.props.updateStore({
      flags: newFlags
    })
    this.setState({flags:newFlags})
  }

  handleheronoteschanged(event){
    let idx = event.target.id
    const newFlags = this.state.flags.map((flag, sidx) =>
    {
      if(idx !== "inputheronotes-"+sidx) return flag;
      return {...flag,heronotes:event.target.value}
    })
    this.props.updateStore({
      flags: newFlags
    })
    this.setState({flags:newFlags})
  }

  handleRecipientChanged(event){
    let idx = event.target.id
    const newFlags = this.state.flags.map((flag, sidx) =>
    {
      if(idx !== "recipient-"+sidx) return flag;
      return {...flag,recipient:event.target.value}
    })
    this.props.updateStore({
      flags: newFlags
    })
    this.setState({flags:newFlags})
  }

  handleflagaddressChanged(event){
    let idx = event.target.id
    const newFlags = this.state.flags.map((flag, sidx) =>
    {
      if(idx !== "flagaddress-"+sidx) return flag;
      return {...flag,flagaddress:event.target.value}
    })
    this.props.updateStore({
      flags: newFlags
    })
    this.setState({flags:newFlags})
  }

  handleflagcityChanged(event){
    let idx = event.target.id
    const newFlags = this.state.flags.map((flag, sidx) =>
    {
      if(idx !== "flagcity-"+sidx) return flag;
      return {...flag,flagcity:event.target.value}
    })
    this.props.updateStore({
      flags: newFlags
    })
    this.setState({flags:newFlags})
  }

  handleflagstateChanged(event){
    let idx = event.target.id
    const newFlags = this.state.flags.map((flag, sidx) =>
    {
      if(idx !== "flagstate-"+sidx) return flag;
      return {...flag,flagstate:event.target.value}
    })
    this.props.updateStore({
      flags: newFlags
    })
    this.setState({flags:newFlags})
  }

  handleflagzipChanged(event){
    let idx = event.target.id
    const newFlags = this.state.flags.map((flag, sidx) =>
    {
      if(idx !== "flagzip-"+sidx) return flag;
      return {...flag,flagzip:event.target.value}
    })
    this.props.updateStore({
      flags: newFlags
    })
    this.setState({flags:newFlags})
  }

  handlebehalfChanged(event){
    let idx = event.target.id
    const newFlags = this.state.flags.map((flag, sidx) =>
    {
      if(idx !== "behalf-"+sidx) return flag;
      return {...flag,behalf:event.target.value}
    })
    this.props.updateStore({
      flags: newFlags
    })
    this.setState({flags:newFlags})
  }


  shiptypechanged(event){
    let idx = event.target.id
    const newFlags = this.state.flags.map((flag, sidx) =>
    {
      if(idx !== "inputtype-"+sidx) return flag;
      return {...flag,shiptype:event.target.value}
    })
    this.props.updateStore({
      flags: newFlags
    })
    this.setState({flags:newFlags})
  }

  addAnotherFlag(event){
    this.setState((prevState)=>({
      flags:[...prevState.flags,{heroprefix:"",herofirstname:"",herolastname:"",herosuffix:"",heroother:"",herocategory:"",herotribute:"",heronotes:"",shiptype:"",flagaddress:"",flagcity:"",flagstate:"",flagzip:"",behalf:this.state.firstname + " " + this.state.lastname,}]
    }));
    this.props.updateStore({
      flags:[...this.state.flags,{heroprefix:"",herofirstname:"",herolastname:"",herosuffix:"",heroother:"",herocategory:"",herotribute:"",heronotes:"",shiptype:"",flagaddress:"",flagcity:"",flagstate:"",flagzip:"",behalf:this.state.firstname + " " + this.state.lastname,}]
    })
  }

  deleteFlag(index){
    let allflags = this.props.getStore().flags
    this.setState((prevState)=>({
      flags:allflags.filter(function(flag,idx){return idx !== index})
    }));
    this.props.updateStore({flags:allflags.filter(function(flag,idx){return idx !== index})})
  }

  fakeupdater(){
    this.setState({flags:this.props.getStore().flags});
  }

  handlePopoverOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  isValidated(){
    let {flags} = this.state
    var any = true;
    flags.map((val, idx)=>{
      let prefix = flags[idx].heroprefix
      let herofirstname = flags[idx].herofirstname
      let herolastname = flags[idx].herolastname
      let herosuffix = flags[idx].herosuffix

      var heroprefixdom = document.getElementById("inputheroprefix-"+idx);
      var herofirstnamedom = document.getElementById("inputherofirstname-"+idx);
      var herolastnamedom = document.getElementById("inputherolastname-"+idx);
      var herosuffixdom = document.getElementById("inputherosuffix-"+idx);
      var shiptypedom = document.getElementById("inputtype-"+idx);
      var flagaddressdom = document.getElementById("flagaddress-"+idx);
      var flagcitydom = document.getElementById("flagcity-"+idx);
      var flagstatedom = document.getElementById("flagstate-"+idx);
      var flagzipdom = document.getElementById("flagzip-"+idx);
      var flagaddresserr = document.getElementById("flagaddresserr-"+idx);
      var flagcityerr = document.getElementById("flagcityerr-"+idx);
      var flagstateerr = document.getElementById("flagstateerr-"+idx);
      var flagziperr = document.getElementById("flagziperr-"+idx);
      var purchasetypeerr = document.getElementById("purchasetypeerr-"+idx)
      var herocategorydom = document.getElementById("inputherocategory-"+idx)
      var herocategoryerr = document.getElementById("herocategoryerr-"+idx)

      if(prefix.length + herofirstname.length + herolastname.length + herosuffix.length > 25){
        heroprefixdom.className = "purchaseInput form-control invalid-border";
        herofirstnamedom.className = "purchaseInput form-control invalid-border";
        herolastnamedom.className = "purchaseInput form-control invalid-border";
        herosuffixdom.className = "purchaseInput form-control invalid-border";
        any = false;
      }else{
        heroprefixdom.className = "purchaseInput form-control";
        herofirstnamedom.className = "purchaseInput form-control";
        herolastnamedom.className = "purchaseInput form-control";
        herosuffixdom.className = "purchaseInput form-control";
      }

      if(shiptypedom.value==="-1"){
        shiptypedom.className = "purchaseInput form-control invalid-border";
        purchasetypeerr.className = "err"
        any = false;
      }else{
        purchasetypeerr.className = "noErr"
        shiptypedom.className = "purchaseInput form-control";
      }

      if(herocategorydom.value === ""){
        herocategoryerr.className = "err"
        herocategorydom.className = "purchaseInput form-control invalid-border";
        any = false;
      }else{
        herocategoryerr.className = "noErr"
        herocategorydom.className = "purchaseInput form-control";
      }

      if(shiptypedom.value==="4"){
        if(flagaddressdom.value === ""){
          flagaddresserr.className = "err"
          flagaddressdom.className = "purchaseInput form-control invalid-border";
          any = false;
        }else{
          flagaddresserr.className = "noErr"
          flagaddressdom.className = "purchaseInput form-control";
        }
        if(flagcitydom.value === ""){
          flagcityerr.className = "err"
          flagcitydom.className = "purchaseInput form-control invalid-border";
          any = false;
        }else{
          flagcityerr.className = "noErr"
          flagcitydom.className = "purchaseInput form-control";
        }
        if(flagstatedom.value === ""){
          flagstateerr.className = "err"
          flagstatedom.className = "purchaseInput form-control invalid-border";
          any = false;
        }else{
          flagstateerr.className = "noErr"
          flagstatedom.className = "purchaseInput form-control";
        }
        if(flagzipdom.value === ""){
          flagziperr.className = "err"
          flagzipdom.className = "purchaseInput form-control invalid-border";
          any = false;
        }else{
          flagziperr.className = "noErr"
          flagzipdom.className = "purchaseInput form-control";
        }
        if(!/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(flagzipdom.value)){
          //Not a real zip code
          flagzipdom.className = "purchaseInput form-control invalid-border";
          any = false;
        }else{
          flagzipdom.className = "purchaseInput form-control";
        }
      }
      return ""
    })
    if(any === true){
      return true;
    }else{
      return false;
    }
  }

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    let {flags} = this.state
    return(
      <div>
        {
          flags.map((val, idx)=>
          {
            let heroprefix = `inputheroprefix-${idx}`,
            herofirstname = `inputherofirstname-${idx}`,
            herolastname = `inputherolastname-${idx}`,
            herosuffix = `inputherosuffix-${idx}`,
            heroother = `inputheroother-${idx}`,
            herocategory = `inputherocategory-${idx}`,
            herotribute = `inputherotribute-${idx}`,
            heronotes = `inputheronotes-${idx}`,
            shiptype = `inputtype-${idx}`,
            tagtribute = `tagtribute-${idx}`,
            taghero = `taghero-${idx}`,
            tagother = `tagother-${idx}`,
            tagcode = `tagcode-${idx}`,
            tagpresent = `tagpresent-${idx}`,
            flagaddress = `flagaddress-${idx}`,
            flagcity = `flagcity-${idx}`,
            flagstate = `flagstate-${idx}`,
            flagzip = `flagzip-${idx}`,
            recipient = `recipient-${idx}`,
            behalf = `behalf-${idx}`,
            purchasetypeerr = `purchasetypeerr-${idx}`,
            herocategoryerr = `herocategoryerr-${idx}`,
            flagaddresserr = `flagaddresserr-${idx}`,
            flagcityerr = `flagcityerr-${idx}`,
            flagstateerr = `flagstateerr-${idx}`,
            flagziperr = `flagziperr-${idx}`;
            return(
              <div key={idx} className="formTextWhite">
                <Grid container spacing={8}>
                  <Grid container spacing={8} item md={12} sm={12}>
                    <Grid container spacing={8}>
                      <Grid item md={2} sm={2} xs={12}>
                        <label>Prefix</label> 
                        <input className="purchaseInput form-control" id={heroprefix} type="text" onChange={this.handleheroprefixchanged} value={flags[idx].heroprefix} ref={heroprefix} placeholder={"Mr, Dr etc."}/>
                      </Grid>
                      <Grid item md={4} sm={4} xs={12}>
                        <label>First Name</label>
                        <input className="purchaseInput form-control" id={herofirstname} type="text" onChange={this.handleherofirstnamechanged} value={flags[idx].herofirstname} ref={herofirstname} required="required" placeholder={"Your Heroes' first name"}/>
                      </Grid>
                      <Grid item md={4} sm={4} xs={12}>
                        <label>Last Name</label>
                        <input className="purchaseInput form-control" id={herolastname} type="text" onChange={this.handleherolastnamechanged} value={flags[idx].herolastname} ref={herolastname} placeholder={"Your Heroes' last name"}/>
                      </Grid>
                      <Grid item md={2} sm={2} xs={12}>
                        <label>Suffix</label>
                        <input className="purchaseInput form-control" id={herosuffix} type="text" onChange={this.handleherosuffixchanged} value={flags[idx].herosuffix} ref={herosuffix} placeholder={"Sr., Jr., etc."}/>
                      </Grid>
                    </Grid>

                    <Grid container spacing={8}>
                      <Grid item md={6} sm={6} xs={12}>
                        <label>Other</label>
                        <input className="purchaseInput form-control" id={heroother} type="text" onChange={this.handleherootherchanged} value={flags[idx].heroother} ref={heroother} placeholder={"US Army, Westerville Police, etc."}/>
                      </Grid>

                      <Grid item md={6} sm={6} xs={12}>
                        <label>Category</label><div className="noErr" ref={herocategoryerr} id={herocategoryerr}> - You must select a Category</div>
                        <Category fakeupdater={() => (this.fakeupdater())} getID={() => (herocategory)} getValue={() => (flags[idx].herocategory)} getStore={() => (this.props.getStore())} updateStore={(u) => {this.props.updateStore(u)}} />
                      </Grid>
                    </Grid>

                    <Grid item md={12} sm={12} xs={12}>
                      <label>Tribute</label>
                      <textarea className="purchaseInput form-control" id={herotribute} onChange={this.handleherotributechanged} value={flags[idx].herotribute} ref={herotribute} placeholder={"Tribute Message, 500 Characters max, 10 lines max"}/>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <label>Notes for this Flag</label>
                      <textarea className="purchaseInput form-control" id={heronotes} type="text" onChange={this.handleheronoteschanged} value={flags[idx].heronotes} ref={heronotes} placeholder={"Other notes, 500 characters max, not printed on the card"}/>
                    </Grid>
                    <Grid container spacing={8}>
                      <Grid item md={5} sm={5} xs={12}>
                        <label>On behalf of</label>
                        <input type="text" className="purchaseInput form-control" id={behalf} onChange={this.handlebehalfChanged} value={flags[idx].behalf} ref={behalf} placeholder={"Presenter name"}/>
                      </Grid>
                      <Grid item md={4} sm={4} xs={12}>
                        <Grid container>
                          <Grid item md={11} sm={11} xs={11}>
                            <label>Purchase Type</label><div className="noErr" ref={purchasetypeerr} id={purchasetypeerr}> - You must select a Purchase Type</div>
                          </Grid>
                          <Grid item md={1} sm={1} xs={1} aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={this.handlePopoverOpen} onMouseLeave={this.handlePopoverClose}>
                            <img alt="info" text-align="right" height="20em" width="20em" src={info}/>
                            <Popover id="mouse-over-popover" className={styles.popover} classes={{ paper: styles.paper}} open={open} anchorEl={anchorEl} onClose={this.handlePopoverClose}>
                              <Typography>
                                Purchase - Pick up your flag and tribute tag at the end of the event on Monday from 1:00PM to 4:00PM.
                                <br />
                                Donate – At the end of the event, this flag will be donated to a deserving organization. You can pick up your tribute tag on Monday from 1:00PM to 4:00PM.
                                <br />
                                Dedicate - You can pick up your tribute tag on Monday from 1:00PM to 4:00PM. This flag will be placed back in inventory for a new purchaser at next year’s event.
                                <br />
                                Ship to my address - Your flag and tribute tag will be shipped to the address you placed in the first part of this order.
                                <br />
                                Ship to another address - Your flag and tribute tag will be shipped to another address, which you may enter when you select this option.
                              </Typography>
                            </Popover>
                          </Grid>
                        </Grid>
                        <select value={flags[idx].shiptype} id={shiptype} ref={shiptype} onChange={this.shiptypechanged} className="purchaseInput form-control">
                          <option value="-1" default>Purchase Type</option>
                          <option value="0">Purchase</option>
                          <option value="1">Dedicate</option>
                          <option value="2">Donate</option>
                          <option value="3">Ship to my Address</option>
                          <option value="4">Ship to another Address</option>
                        </select>
                      </Grid>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <button className="purchaseInput btn btn-danger delete-button" onClick={()=> {if(window.confirm('Are you sure you want to delete this flag?')) this.deleteFlag(idx)} }>Remove this Flag from order</button>
                    </Grid>
                    { parseInt(val["shiptype"])===4 &&
                      <Grid container spacing={8}>
                        <Grid item md={12} sm={12}  xs={12}>
                          <label>Address</label><div className="noErr" ref={flagaddresserr} id={flagaddresserr}> - You must enter an address</div>
                          <input className="purchaseInput form-control" type="text" id={flagaddress} ref={flagaddress} onChange={this.handleflagaddressChanged} value={flags[idx].flagaddress} required="required"/>
                        </Grid>
                        <Grid container>
                          <Grid item md={6} sm={12}  xs={12}>
                            <label>City</label><div className="noErr" ref={flagcityerr} id={flagcityerr}> - You must enter a city</div>
                            <input className="purchaseInput form-control" type="text" id={flagcity} ref={flagcity} onChange={this.handleflagcityChanged} value={flags[idx].flagcity} required="required"/>
                          </Grid>
                          <Grid item md={3} sm={6}  xs={12}>
                            <label>State</label><div className="noErr" ref={flagstateerr} id={flagstateerr}> - You must select a state</div>
                            <FlagStates fakeupdater={() => (this.fakeupdater())} getStore={() => (this.props.getStore())} getID={() => (flagstate)} getValue={() => (flags[idx].flagstate)} updateStore={(u) => {this.props.updateStore(u)}} />
                          </Grid>
                          <Grid item md={3} sm={6}  xs={12}>
                            <label>Zip</label><div className="noErr" ref={flagziperr} id={flagziperr}> - You must enter a zip code</div>
                            <input className="purchaseInput form-control" type="text" id={flagzip} ref={flagzip} onChange={this.handleflagzipChanged} value={flags[idx].flagzip} required="required"/>
                          </Grid>
                          <Grid item md={3} sm={6}  xs={12}>
                            <label>Recipient</label>
                            <input className="purchaseInput form-control" type="text" id={recipient} ref={recipient} onChange={this.handleRecipientChanged} value={flags[idx].recipient} required="required"/>
                          </Grid>
                        </Grid>
                      </Grid>
                    }
                  </Grid>
                  <Hidden>
                    <Grid container item md={12} sm={12}>
                      <div className="new-tag-tag">
                        <div className="new-tag-header">
                          <div className="new-tag-center">
                            <img className="new-tag-circle" src={Circle} alt=""/>
                          </div>
                        </div>
                        <div className="new-tag-body">
                          <div className="new-tag-inner">
                            <div id={taghero} className="new-tag-heroname">{flags[idx].heroprefix} {flags[idx].herofirstname} {flags[idx].herolastname} {flags[idx].herosuffix}</div>
                            <div id={tagother} className="new-tag-heroother">{flags[idx].heroother}</div>
                            <div id={tagtribute} className="new-tag-tribute">{flags[idx].herotribute}</div>
                            <div className="row">
                              <div className="new-tag-bottom-left col-6">
                                <p id={tagcode} className="new-tag-herocode">{flags[idx].herocategorylongtext}</p>
                              </div>
                              <div className="new-tag-bottom-right col-6">
                                <p id={tagpresent} className="new-tag-presenter">{flags[idx].behalf}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Hidden>
                </Grid>
              </div>
            )
          })
        }
        <button className="purchaseInput btn btn-light" onClick={this.addAnotherFlag}>Add another Flag to order</button>
        <Typography variant="subtitle2" color="inherit">Flag Purchases are $35<br/> Flag Dedications are $20<br/> Flag Donations are $35<br/> Purchasing Shipped Flags is $35 (each flag) as well as $7.50 shipping for up to two flags to the same address, with additional shipping for more</Typography>
      </div>
    )
  }
}

export default Flag;