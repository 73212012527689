import React from 'react';
import Grid from '@material-ui/core/Grid';
import FoHFoot from './Foot.js'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import ohiohealth from "./img/2021logos/OhioHealth.png"
import pearl from "./img/2021logos/pearl.png"
//import mtcarmel from "./img/2021logos/mtcarmel.png"
import vandalia from "./img/2021logos/VandaliaBig.png"
import westcamp from './img/2021logos/Westcamp.png'
import marzetti from "./img/2021logos/Marzetti.png"
import roush from "./img/2021logos/RoushHonda.png"
import maloney from "./img/2021logos/Maloney.png"
import lehmandaman from "./img/2021logos/Lehman.png"
import wichert from "./img/2021logos/Wichert.png"
//import alca from "./img/2021logos/ALCA.png"
import edjones from "./img/2021logos/EdJones.png"
//import amc from "./img/2021logos/AssetManagement.png"
//import kguard from "./img/2021logos/KGuard.png"
import g11 from "./img/2021logos/GroupEleven.png"
import infintech from "./img/2021logos/Infintech.png"
import metz from "./img/2021logos/MetzBailey.png"
import columbusgymnastics from './img/2021logos/ColsGym.png'
import flaherty from './img/2021logos/Flaherty.png'
//import ess from "./img/2021logos/ESS.png"
import dasco from "./img/2021logos/DASCO.png"
import copc from './img/2021logos/COPC.png'
//import blairit from "./img/2021logos/BlairIT.png"
import panera from "./img/2021logos/Panera.png"
//import toukan from "./img/2021logos/Toukan.png"
import warm from "./img/2021logos/WARM.png"
import hill from "./img/2021logos/Hill.png"
import lav from "./img/2021logos/LAV.png"
//import durant from "./img/2021logos/Durant.png"
//import floresta from "./img/2021logos/Floresta.png"
//import rudderow from "./img/2021logos/RudderowCPA.png"
//import bin from './img/2021logos/Bins.png'
//import brown from './img/2021logos/Brown.png'
//import franklin from './img/2021logos/Franklin.png'
//import westerville from './img/2021logos/WVille.png'
//import skyline from './img/2021logos/Skyline.png'
import genjigo from './img/2021logos/Genjigo.png'
import vertiv from './img/2021logos/Vertiv.svg'
//import chase from './img/2021logos/Chase.png'
import oldPride from './img/2021logos/OldPride.png'
import overlord from './img/2021logos/Overlord.png'
import fleetfeet from './img/2021logos/FleetFeet.svg'
import qts from './img/2021logos/QTS.png'
import bobevansfarms from './img/2021logos/BobEvansFarms.png'
import fibertech from './img/2021logos/Fiber-Tech.jpg'

export class Support extends React.Component {
  render() {
    return (
      <div className="App">
        <div className="App-content">
          <Grid container spacing={8} justify="center">
            <Grid container spacing={8} justify="center">
              <Grid item md={8} sm={8} xs={12}>
                <Typography variant="h4" align="center" color="inherit">Support the Event</Typography><br/>
              </Grid>
            </Grid>
            <Grid container spacing={8} justify="center">
              <Grid item md={8} sm={8} xs={12}>
                <Typography variant="h6" align="center" color="inherit">The Field of Heroes is run and organized by the <a target="_blank" rel="noopener noreferrer" href="https://www.westervillesunrise.org">Westerville Sunrise Rotary Club</a> and all funds are handled by the Westerville Sunrise Foundation, a 501(c)(3) entity. The Field of Heroes is a community outreach service project for the Westerville Sunrise Rotary and not a fundraising effort. A partnership of community organizations and businesses come together to underwrite some of the expenses of the event. We are currently seeking patrons to help underwrite the cost of presenting the Field of Heroes, so that this special event can continue to be shared with the community.</Typography><br/>
              </Grid>
            </Grid>
            <Grid container spacing={8} justify="center">
              <Grid item>
                <Button variant="contained" target="_blank" href="https://fohapi.groupeleven.com/sponsor/2024PatronLevels.pdf">Patron Benefits</Button>
              </Grid>
              <Grid item>
                <Button variant="contained" target="_blank" href="https://fohapi.groupeleven.com/sponsor/2024PatronElectionForm.pdf">Patron Form</Button>
              </Grid>
            </Grid>
            <Grid container spacing={8} justify="center">
              <div className="divider"/>
              <h3>Presenting Patrons</h3>
              <div className="divider"/>
              <Grid item md={8} sm={10}  xs={12} container justify="center">
                <Grid item md={3} sm={6}  xs={12}>
                  <h4>&nbsp;</h4>
                  <a href="https://www.fiber-tech.net/"><img className="Sponsor-Image" alt="Fiber-Tech Logo" src={fibertech}/></a>
                </Grid>
                <Grid item md={3} sm={6}  xs={12}>
                  <h4>&nbsp;</h4>
                  <a href="https://www.lawyersforamericanvets.com/"><img className="Sponsor-Image" alt="Lawyers for American Vets" src={lav}/></a>
                </Grid>
                <Grid item md={3} sm={6}  xs={12}>
                  <h4 style={{textAlign:"center"}}>5K Run/Walk Presenter</h4>
                  <a href="http://www.ohiohealth.com"><img className="Sponsor-Image" alt="Ohio Health Logo" src={ohiohealth}/></a>
                </Grid>
                <Grid item md={3} sm={6}  xs={12}>
                  <h4>&nbsp;</h4>
                  <img className="Sponsor-Image" alt="Old Pride Truck Center Logo" src={oldPride}/>
                </Grid>
                <Grid item md={3} sm={6}  xs={12}>
                  <h4>&nbsp;</h4>
                  <a href="https://pinsourcing.com/"><img className="Sponsor-Image" alt="Pearl Interactive Network Logo" src={pearl}/></a>
                </Grid>
                <Grid item md={3} sm={6}  xs={12}>
                  <h4>&nbsp;</h4>
                  <a href="https://www.tmarzetticompany.com/"><img className="Sponsor-Image" alt="Marzetti" src={marzetti}/></a>
                </Grid>
                <Grid item md={3} sm={6}  xs={12}>
                  <h4>&nbsp;</h4>
                  <a href="https://qtsdatacenters.com/"><img className="Sponsor-Image" alt="QTS Datacenters logo" src={qts}/></a>
                </Grid>
                <Grid item md={3} sm={6}  xs={12}>
                  <h4>&nbsp;</h4>
                  <a href="https://vandaliarental.com/"><img className="Sponsor-Image" alt="Vandalia Rentals Logo" src={vandalia}/></a>
                </Grid>
                <Grid item md={3} sm={6}  xs={12}>
                  <h4>&nbsp;</h4>
                  <a href="https://www.vertiv.com/en-emea/"><img className="Sponsor-Image" style={{marginTop:'3.5%',paddingRight:'10px',backgroundColor:'white',height:'82.5%',maxWidth:'395px'}} alt="Vertiv" src={vertiv}/></a>
                </Grid>
                {/*
                */}
                <Grid item md={3} sm={6}  xs={12}>
                  <h4>&nbsp;</h4>
                  <a href="http://www.westcamppress.com/"><img className="Sponsor-Image" alt="WestCamp Logo" src={westcamp}/></a>
                </Grid>
                {/*
                <Grid item md={4} sm={6}  xs={12}>
                  <h4>&nbsp;</h4>
                  <a href="https://www.mountcarmelhealth.com/location/mount-carmel-st-anns"><img className="Sponsor-Image" alt="Mount Carmel St. Ann's" src={mtcarmel}/></a>
                </Grid>
                <Grid item md={3} sm={6}  xs={12}>
                  <a href="http://www.chase.com"><img className="Sponsor-Image" alt="Chase Bank" src={chase}/></a>
                </Grid>
                <Grid item md={3} sm={6}  xs={12}>
                  <a href="https://www.medigold.com"><img className="Sponsor-Image" alt="Medigold" src={medigold}/></a>
                </Grid>
                <Grid item md={3} sm={6}  xs={12}>
                  <a href="https://www.columbiagasohio.com/"><img className="Sponsor-Image" alt="Columbia" src={columbia}/></a>
                </Grid>
            	*/}
              </Grid>
            </Grid>
            <Grid container spacing={8} justify="center">
              <div className="divider"/>
              <h3>Community Engagement Patrons</h3>
              <div className="divider"/>
              <Grid item md={10} sm={10}  xs={12} container spacing={8} justify="center">
                {/*
                <Grid item lg={3} md={4} sm={6}  xs={12}>
                  <a href="https://cgagymnastics.com/"><img className="Sponsor-Image" alt="Columbus Gymnastics Academy" src={columbusgymnastics}/></a>
                </Grid>
                <Grid item lg={3} md={4} sm={6}  xs={12}>
                  <a href="https://www.copcp.com/"><img className="Sponsor-Image" alt="Central Ohio Primary Care" src={copc}/></a>
                </Grid>
                <Grid item lg={3} md={4} sm={6}  xs={12}>
                  <a href="https://www.godasco.com/"><img className="Sponsor-Image" alt="DASCO Home Medical Equipment" src={dasco}/></a>
                </Grid>
                <Grid item lg={3} md={4} sm={6}  xs={12}>
                  <a href="https://www.edwardjones.com/en_US/fa/index.html&CIRN=854803"><img className="Sponsor-Image" alt="Edward Jones" src={edjones}/></a>
                </Grid>
                <Grid item lg={3} md={4} sm={6}  xs={12}>
                  <a href="https://www.genjigo.com/"><img className="Sponsor-Image" alt="Genjigo Fresh Hibachi Bowls" src={genjigo}/></a>
                </Grid>
                */}
                <Grid item lg={3} md={4} sm={6}  xs={12}>
                  <a href="https://www.bobevansgrocery.com/"><img className="Sponsor-Image" alt="Bob Evan's Farms Logo" src={bobevansfarms}/></a>
                </Grid>
                <Grid item lg={3} md={4} sm={6}  xs={12}>
                  <a href="https://www.groupeleven.com"><img className="Sponsor-Image" alt="GroupEleven Consulting" src={g11}/></a>
                </Grid>
                {/*
                <Grid item lg={3} md={4} sm={6}  xs={12}>
                  <a href="https://www.hillfuneral.com/"><img className="Sponsor-Image" alt="Hill Funeral" src={hill}/></a>
                </Grid>
                <Grid item lg={3} md={4} sm={6}  xs={12}>
                  <img className="Sponsor-Image" alt="Infintech Mark Morgan" src={infintech}/>
                </Grid>
                */}
                <Grid item lg={3} md={4} sm={6}  xs={12}>
                  <img className="Sponsor-Image" alt="James Flaherty Co LPA" src={flaherty}/>
                </Grid>
                <Grid item lg={3} md={4} sm={6}  xs={12}>
                  <a href="https://www.fleetfeet.com/"><img className="Sponsor-Image" style={{backgroundColor:'white',marginTop:"3%",height:"83%"}} alt="Fleet Feet Logo" src={fleetfeet}/></a>
                </Grid>
                <Grid item lg={3} md={4} sm={6}  xs={12}>
                  <a href="https://www.lehmandaman.com/"><img className="Sponsor-Image" alt="Lehman Daman" src={lehmandaman}/></a>
                </Grid>
                <Grid item lg={3} md={4} sm={6}  xs={12}>
                  <img className="Sponsor-Image" alt="Overlord Living History Logo" src={overlord}/>
                </Grid>
                <Grid item lg={3} md={4} sm={6}  xs={12}>
                  <a href="https://www.panerabread.com/en-us/home.html"><img className="Sponsor-Image" alt="Panera Bread" src={panera}/></a>
                </Grid>
                <Grid item lg={3} md={4} sm={6}  xs={12}>
                  <a href="http://www.maloneynovotny.com"><img className="Sponsor-Image" alt="Maloney Novotny" src={maloney}/></a>
                </Grid>
                <Grid item lg={3} md={4} sm={6}  xs={12}>
                  <a href="http://www.metzbailey.com/"><img className="Sponsor-Image" alt="Metz Bailey" src={metz}/></a>
                </Grid>
                <Grid item lg={3} md={4} sm={6}  xs={12}>
                  <a href="https://www.roushhonda.com/"><img className="Sponsor-Image" alt="Roush Honda" src={roush}/></a>
                </Grid>
                <Grid item md={3} sm={6}  xs={12}>
                  <a href="https://warmwesterville.org/"><img className="Sponsor-Image" alt="Westerville Area Resource Ministry Logo" src={warm}/></a>
                </Grid>
                {/*
                <Grid item lg={3} md={4} sm={6}  xs={12}>
                  <a href="https://www.wichert.com/"><img className="Sponsor-Image" alt="Wichert Insurance" src={wichert}/></a>
                </Grid>
                */}
                {/*
                <Grid item lg={3} md={4} sm={6}  xs={12}>
                  <a href="https://www.chase.com/"><img className="Sponsor-Image" alt="Chase" src={chase}/></a>
                </Grid>
                <Grid item lg={3} md={4} sm={6}  xs={12}>
                  <img className="Sponsor-Image" alt="Engineering Support Services" src={edjones}/>
                </Grid>
                <Grid item lg={3} md={4} sm={6}  xs={12}>
                  <a href="http://www.kguard.com"><img className="Sponsor-Image" alt="KGuard" src={kguard}/></a>
                </Grid>
                <Grid item lg={3} md={4} sm={6}  xs={12}>
                  <a href="https://www.skylinechili.com/"><img className="Sponsor-Image" alt="Skyline Chili" src={skyline}/></a>
                </Grid>
                <Grid item lg={3} md={4} sm={6}  xs={12}>
                  <a href="http://www.raymondjames.com/westervilleohio/"><img className="Sponsor-Image" alt="Asset Management Consultants" src={amc}/></a>
                </Grid>
                <Grid item lg={3} md={4} sm={6}  xs={12}>
                  <img className="Sponsor-Image" alt="ALCA Associates" src={alca}/>
                </Grid>
                <Grid item lg={3} md={4} sm={6}  xs={12}>
                  <a href="#"><img className="Sponsor-Image" alt="Central Ohio Primary Care" src={copc}/></a>
                </Grid>
                <Grid item lg={3} md={4} sm={6}  xs={12}>
                  <a href="https://www.morganstanley.com/pub/content/msdotcom/en/graystone.html"><img className="Sponsor-Image" alt="Graystone Morgan Stanley" src={morganstanley}/></a>
                </Grid>
                <Grid item lg={3} md={4} sm={6}  xs={12}>
                  <a href="http://www.westervillesunrise.org"><img className="Sponsor-Image" alt="Westerville Sunrise Rotary" src={westervillesunrise}/></a>
                </Grid>
                <Grid item lg={3} md={4} sm={6}  xs={12}>
                  <a href="https://www.roushhonda.com/"><img className="Sponsor-Image" alt="Roush Honda" src={roush}/></a>
                </Grid>
                <Grid item lg={3} md={4} sm={6}  xs={12}>
                  <a href="https://franklinequipment.com/"><img className="Sponsor-Image" alt="Franklin Equipment" src={franklin}/></a>
                </Grid>
                <Grid item lg={3} md={4} sm={6}  xs={12}>
                  <a href="http://www.retirewithsuccess.com/"><img className="Sponsor-Image" alt="Eischen Financial Group" src={eischen}/></a>
                </Grid>
            	*/}
              </Grid>
            </Grid>
            <Grid container spacing={8} justify="center">
              <div className="divider"/>
              <h3>Stars &amp; Stripes Patrons</h3>
              <div className="divider"/>
              <Grid item md={10} sm={10}  xs={12} container spacing={8} justify="center">
                <Grid item md={12} sm={12}  xs={12}>
                  <Typography variant="h5" style={{color: "white",textAlign:"center"}}>Bartlett Tree Experts</Typography>
                </Grid>
                <Grid item md={12} sm={12}  xs={12}>
                  <Typography variant="h5" style={{color: "white",textAlign:"center"}}>Central Ohio Primary Care</Typography>
                </Grid>
                <Grid item md={12} sm={12}  xs={12}>
                  <Typography variant="h5" style={{color: "white",textAlign:"center"}}>Columbus Gas of Ohio/Nisource</Typography>
                </Grid>
                <Grid item md={12} sm={12}  xs={12}>
                  <Typography variant="h5" style={{color: "white",textAlign:"center"}}>Dan Hare Consulting</Typography>
                </Grid>
                <Grid item md={12} sm={12}  xs={12}>
                  <Typography variant="h5" style={{color: "white",textAlign:"center"}}>Danbury Westerville-Parkside Village</Typography>
                </Grid>
                <Grid item md={12} sm={12}  xs={12}>
                  <Typography variant="h5" style={{color: "white",textAlign:"center"}}>Dasco Home Medical Equipment</Typography>
                </Grid>
                <Grid item md={12} sm={12}  xs={12}>
                  <Typography variant="h5" style={{color: "white",textAlign:"center"}}>Dave & Jane Collinsworth</Typography>
                </Grid>
                <Grid item md={12} sm={12}  xs={12}>
                  <Typography variant="h5" style={{color: "white",textAlign:"center"}}>Donna H. Rudderow, CPA</Typography>
                </Grid>
                <Grid item md={12} sm={12}  xs={12}>
                  <Typography variant="h5" style={{color: "white",textAlign:"center"}}>Guide Mortgage, LLC</Typography>
                </Grid>
                <Grid item md={12} sm={12}  xs={12}>
                  <Typography variant="h5" style={{color: "white",textAlign:"center"}}>Jim and Connie Caldwell</Typography>
                </Grid>
                <Grid item md={12} sm={12}  xs={12}>
                  <Typography variant="h5" style={{color: "white",textAlign:"center"}}>John and Linda Bokros</Typography>
                </Grid>
                <Grid item md={12} sm={12}  xs={12}>
                  <Typography variant="h5" style={{color: "white",textAlign:"center"}}>Mary Chandler</Typography>
                </Grid>
                <Grid item md={12} sm={12}  xs={12}>
                  <Typography variant="h5" style={{color: "white",textAlign:"center"}}>Middlefield Banking Company</Typography>
                </Grid>
                <Grid item md={12} sm={12}  xs={12}>
                  <Typography variant="h5" style={{color: "white",textAlign:"center"}}>Otterbein University</Typography>
                </Grid>
                <Grid item md={12} sm={12}  xs={12}>
                  <Typography variant="h5" style={{color: "white",textAlign:"center"}}>Maze & Co. - Cutler Real Estate</Typography>
                </Grid>
                <Grid item md={12} sm={12}  xs={12}>
                  <Typography variant="h5" style={{color: "white",textAlign:"center"}}>Skyline Chili</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className="divider-no-margin"/>
          <FoHFoot/>
        </div>
      </div>
    );
  }
}

export default Support;