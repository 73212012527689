import React from 'react';
import patron from "./img/HomePagePatronSquare.jpg";
import purchase from "./img/HomePagePurchaseFlagSquare.jpg";
import programinfo from "./img/HomePageProgramInfo.jpg";
import volunteer from "./img/HomePageVolunteerSquare.jpg";
import videos from "./img/HomePageVideoSquare.jpg";
import photos from "./img/HomePagePhotoSquare.jpg";
import header from "./img/header.jpg";
import FoHFoot from './Foot.js';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
//import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
//import ResponsiveEmbed from 'react-responsive-embed';
import Hidden from '@material-ui/core/Hidden';

export class MainPage extends React.Component {
  render() {
    return (
      <div className="App">
        <div className="App-content">
          <Grid container justify="center">
            <Grid container spacing={8} justify="center">
              <Grid item md={8} sm={12}  xs={12}>
                <Typography variant="h3" color="inherit" align="center">Honor your Personal Heroes, whoever they may be.</Typography><br/>
              </Grid>
              <Grid item md={8} sm={12}  xs={12}>
                <div style={{position: "relative", height: "0px", overflow: "hidden", "maxWidth": "100%", "paddingBottom": "56.25%"}}>
                  <iframe width="100%" height="650" src="https://www.youtube.com/embed/NhUDRNNFQiw" title="YouTube video player" frameBorder="0" allow="accelerometer; encrypted-media; gyroscope;" allowFullScreen></iframe>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <div className="divider"/>
          <Grid container spacing={8} justify="center">
            <Grid item container md={10} sm={10} xs={12} spacing={8}>
              <Grid item md={4} sm={6}  xs={12}>
                <Card className="tile-card" style={{height: '100%'}}>
                  <CardHeader title="Become a Patron"/>
                  <a href="/support"><CardMedia style = {{ height: 0, paddingTop:'56%'}} image={patron} title="Patron"/></a>
                  <CardContent>
                    <Typography variant="subtitle1">We are proud to continue this Memorial Day tradition including a tribute to Westerville’s Veterans, an education tent, and a Saturday night concert. Thank you to all of the patrons of the 2024 Field of Heroes. We are accepting patrons this year to support event activities, so please help us honor Westerville’s Heroes by clicking on Support the Event above.</Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item md={4} sm={6} xs={12}>
                <Card className="tile-card" style={{height: '100%'}}>
                  <CardHeader title="Purchase a Flag"/>
                  <a href="/purchase"><CardMedia style = {{ height: 0, paddingTop:'56%'}} image={purchase} title="Purchase"/></a>
                  <CardContent>
                    <Typography variant="subtitle1">In addition to our traditional flag sales, we started a tradition of having a commemorative item representing each year. This year we honor Westerville’s Heroes, and you can support us not only by purchasing a flag for your hero but also by purchasing this year’s commemorative coin.</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Card className="tile-card" style={{height: '100%'}}>
                  <CardHeader title="Program Info"/>
                  <a href="/schedule"><CardMedia style = {{ height: 0, paddingTop:'56%'}} image={programinfo} title="Program Info"/></a>
                  <CardContent>
                    <Typography variant="subtitle1">The first field of its kind in Central Ohio, the Field of Heroes has become a truly unique and inspiring event. This year's theme will be Ohio Heroes of Flight.</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Card className="tile-card" style={{height: '100%'}}>
                  <CardHeader title="Volunteer"/>
                  <a href="/volunteer"><CardMedia style = {{ height: 0, paddingTop:'56%'}} image={volunteer} title="Volunteer" /></a>
                  <CardContent>
                    <Typography variant="subtitle1">Volunteer opportunities range from field greeters and order-takers to overnight security personnel.</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Card className="tile-card" style={{height: '100%'}}>
                  <CardHeader title="Videos"/>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/user/FieldOfHeroes"><CardMedia style = {{ height: 0, paddingTop:'56%'}} image={videos} title="Videos"/></a>
                  <CardContent>
                    <Typography variant="subtitle1">Thanks to the support from local TV stations, take a look at the Field of Heroes in previous years. Head to our YouTube page to see more.</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Card className="tile-card" style={{height: '100%'}}>
                  <CardHeader title="Photos"/>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/fieldofheroes"><CardMedia style = {{ height: 0, paddingTop:'56%'}} image={photos} title="Photos"/></a>
                  <CardContent>
                    <Typography variant="subtitle1">Wonderful photos were taken from visitors at the event. Head to our Facebook page to see more.</Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <div className="divider"/>
          <Grid container justify="center" spacing={8}>
            <Grid container spacing={8} justify="center">
              <Grid container spacing={8} justify="center">
                <Grid item md={8} sm={8} xs={12}>
                  <Hidden><Typography variant="h4" align="center" color="inherit">Save The Dates<br/><br/></Typography></Hidden>
                </Grid>
              </Grid>
              <Grid container spacing={8} justify="center">
                <Grid item md={1} sm={1}></Grid>
                <Grid item md={3} sm={5} xs={12}>
                  <Typography variant="h5" align="left" color="inherit">May 24 - May 27, 2024</Typography>
                  <Typography variant="subtitle1" align="left" color="inherit">Please join us for the 16th annual Field of Heroes, as Central Ohioans remember and honor their personal heroes.</Typography>
                  <div className="divider"/>
                  <Typography variant="h5" align="left" color="inherit">Special Tribute</Typography>
                  <Typography variant="subtitle1" align="left" color="inherit">Reflect. Honor. Tradition. This year's theme will be Ohio Heroes in Flight.</Typography>
                  <div className="divider"/>
                  <Typography variant="h5" align="left" color="inherit">Sunday, May 26, 2024</Typography>
                  <Typography variant="subtitle1" align="left" color="inherit">5k Run/Walk for Heroes.</Typography>
                  <div className="divider"/>
                  <Typography variant="h5" align="left" color="inherit">Stay Connected</Typography>
                  <Typography variant="subtitle1" align="left" color="inherit">Follow the Field of Heroes on <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/FieldOfHeroesOH">Twitter</a>, and <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/fieldofheroes">"Friend" us on Facebook</a></Typography>
                </Grid>
                <Grid item md={7} sm={7} xs={12}>
                  <img width="100%" src={header} alt="flags"/>
                </Grid>
                <Grid item md={1} sm={1}></Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className="divider"/>
          <FoHFoot/>
        </div>
      </div>
    );
  }
}

export default MainPage;