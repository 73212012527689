import React from 'react';
import FoHFoot from './Foot.js'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export class Program extends React.Component {
  render() {
    return (
      <div className="App">
        <content className="App-content">
          <Grid container spacing={8}>
            <Grid item md={8} sm={8}  xs={12}>
              <Typography variant="h3" align="center" color="inherit">Program Information</Typography><br/>
              <Typography variant="subtitle1" color="inherit">
                On Memorial Day weekend 2019, the Westerville Sunrise Rotary Club will host the 11th Annual Field of Heroes across from the Westerville Community Center on Cleveland Avenue in Westerville, Ohio. The Field of Heroes is a unique opportunity for our community members to honor their personal heroes - whoever they are... a family member; a friend; a veteran; a member of the armed services; a favorite teacher; a police officer; a firefighter or any other person who has made an impact on their lives.
                <br/>
                The Field of Heroes is a spectacular visual display of 3,000 American flags. The 3' x 5' flags stand 8 feet tall in perfect rows and columns across a field of green. The field is set up so that visitors can walk among the flags, reading dedications and reflecting upon their personal heroes.
                <br/>
                The Westerville Sunrise Rotary club invites you to join us to show support for your hero. Become a part of this community event by purchasing a flag that will stand proudly in the Field of Heroes. Online flag orders begin roughly in April. When purchasing a flag, there are three options to choose from:
              </Typography>
              <ul>
                <li className="FoH-Volunteer-List">
                  <Typography variant="subtitle1" color="inherit"><b>Purchase:</b> After the Memorial Day weekend the flag is yours to keep, or you can ship the flag to your hero.</Typography>
                </li>
                <li className="FoH-Volunteer-List">
                  <Typography variant="subtitle1" color="inherit"><b>Dedicate:</b> Your purchased flag will return to our inventory so we can use the flag for new purchases next year. You will get to keep your dedication tag and certificate.</Typography>
                </li>
                <li className="FoH-Volunteer-List">
                  <Typography variant="subtitle1" color="inherit"><b>Donate:</b> At the end of the event, the flag you purchased will be donated to a charitable organization that supports active military, veterans, public safety personnel or their families. You will get to keep your dedication tag and certificate.</Typography>
                </li>
              </ul>
              <Typography variant="subtitle1" color="inherit">
                No two years are ever the same! Flag dedications last only for the event year they are purchased in. Each year starts with all new dedication tags so the stories you read change year to year!
              </Typography>
              <br/>
              <Typography variant="h6" align="center" color="inherit">
                This is an opportunity for us all to come together as a community to remember, to honor, and to heal.
              </Typography>
            </Grid>
            <div className="Vertical-Line"/>
            <Grid item md={3} sm={3}  xs={12}>
              <Typography variant="h3" align="center" color="inherit">Tentative Schedule</Typography><br/>
              <ul>
                <li className="FoH-Volunteer-List">
                  <Typography variant="subtitle1" color="inherit">Friday, May 24<br/>8:30pm - Taps</Typography>
                </li>
                <li className="FoH-Volunteer-List">
                  <Typography variant="subtitle1" color="inherit">Saturday, May 25<br/>Noon - Reading of Names on Dedicated Flags<br/>6:00 pm - I’ll Be Seeing You performed by the Westerville Public Library<br/>7:30 pm - Free Concert performed by the Westerville Community Band<br/>8:30 pm - (following concert) Echo Taps<br/>12 midnight - Flag Sales Close for the night</Typography>
                </li>
                <li className="FoH-Volunteer-List">
                  <Typography variant="subtitle1" color="inherit">Sunday, May 26<br/>8:00 am - Field of Heroes 5K Run/Walk<br/>Noon - Reading of Names on Dedicated Flags<br/>5:00 pm - Flag Retirement Ceremony presented by the Boy Scouts of America<br/>7:00 pm - Memorial Ceremony (Welcome Home)<br/>8:30 pm - Taps<br/>12 midnight - Flag Sales Close for the Night</Typography>
                </li>
                <li className="FoH-Volunteer-List">
                  <Typography variant="subtitle1" color="inherit">Monday, May 27<br/>Noon - Reading of Names on Dedicated Flags<br/>12:30 pm - Closing Ceremony<br/>1:00 - 3:30 - Flag Pick Up</Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
          <div className="divider-no-margin"/>
          <FoHFoot/>
        </content>
      </div>
    );
  }
}

export default Program;