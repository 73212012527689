import React from 'react';
import axios from "axios";

export class States extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			singlestate:props.getStore().state,
			states:[]
		}
		this.changestates = this.changestates.bind(this);
	}

	componentDidMount(){
		axios.get("https://fohapi.groupeleven.com/states/")
			.then(response => {
				const states = response.data
				this.setState({states})
			})
	}

	changestates(event){
	    this.props.updateStore({
	    	state: event.target.value
	    })
	    this.setState({singlestate:event.target.value})
	}

	render(){
		let {states} = this.state
		return (
			<select onChange={this.changestates} id="state" ref="state" value={this.state.singlestate} className="purchaseInput form-control">
			<option key="" value="">Pick a state</option>
			{
				states.map((index)=>
				{
					return(<option key={index["StateAbrev"]} value={index["StateAbrev"]}>{index["State"]}</option>)
				})
			}
			</select>
		)
	}
}

export default States