import React from 'react';
import Multistep from 'react-stepzilla'
import FoHFoot from './Foot.js'
import Person from './Person.js'
import Flag from './Flag.js'
import Payment from './Payment.js'
import Confirm from './Confirmation.js'
import Grid from '@material-ui/core/Grid';

export class Purchase extends React.Component{
  constructor(props){
    super(props);
    this.state= {};
    this.sampleStore = {
      firstname: "",
      lastname: "",
      organization: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      email: "",
      notes: "",
      flags:[{
        heroprefix: "",
        herofirstname: "",
        herolastname: "",
        herosuffix: "",
        heroother: "",
        herocategory: "",
        herotribute: "",
        heronotes: "",
        herocategorylongtext: "",
        shiptype:"",
        flagaddress:"",
        flagcity:"",
        flagstate:"",
        flagzip:"",
        recipient:"",
        behalf:null,
      }],
      usstates:{},
    };
  }

  getStore() {
    return this.sampleStore;
  }

  updateStore(update) {
    this.sampleStore = {
      ...this.sampleStore,
      ...update,
    }
  }

  render(){
    const steps = [
      {name: "Personal Info", component:<Person getStore={() => (this.getStore())} updateStore={(u) => {this.updateStore(u)}} />},
      {name: "Hero Info", component:<Flag getStore={() => (this.getStore())} updateStore={(u) => {this.updateStore(u)}} />},
      {name: "Confirmation", component:<Confirm getStore={() => (this.getStore())} updateStore={(u) => {this.updateStore(u)}} />},
      {name: "Payment", component:<Payment getStore={() => (this.getStore())} updateStore={(u) => {this.updateStore(u)}} />},
    ]

    return(<div className="App"><div className="App-content">
      <Grid container justify="center">
        <Grid item md={8} sm={12} xs={12}>
          <Multistep stepsNavigation={false} showNavigation={true} startAtStep={0} prevBtnOnLastStep={false} steps={steps}/>
        </Grid>
      </Grid>
      <FoHFoot/>
    </div></div>
    )
  }
}

export default Purchase