import React from 'react';
import FoHFoot from './Foot.js'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
//import race from "./img/race2019.jpg";
import racepicture from "./img/5kPage.jpg";
import Button from '@material-ui/core/Button';

export class Fivek extends React.Component {
  render() {
    return (
      <div className="App">
        <div className="App-content">
          <Grid container spacing={8}>
            <Grid item md={1}/>
            <Grid item md={10} sm={12} xs={12} align="left" container>
              <Typography variant="h3" align="center" color="inherit">The Field of Heroes 5k Run/Walk</Typography><br/>
              <Grid container>
                <Grid item xs={12}>
                  <img width="100%" src={racepicture} alt="Race"/>
                </Grid>
              </Grid>
              <Typography variant="h6" color="inherit">
                This family/friendly 5k Run/Walk offers unique opportunities to finish among 3,000 full size American flags!
                <br/><br/>
                Sign up for the Field of Heroes 5k Run/Walk for an active and alternative way to honor your heroes in a unique and breathtaking setting. This flat course is on paved recreation paths, against one of the most memorable backdrops you’ll ever experience!
                <br/><br/>
                This event is limited to 1,200 participants so register early to secure your spot!
              </Typography>
              <Typography variant="h6" style={{width:"100%"}} color="inherit">Date & Time: Sunday, May 26th, 2024 at 8:00 AM</Typography>
              <Typography variant="h6" style={{width:"100%"}} color="inherit">Location: Westerville Sports Complex (Cleveland Ave. & County Line Rd.)</Typography>
              <Typography variant="h6" style={{width:"100%"}}  color="inherit">Price*:</Typography>
              <Typography variant="subtitle1" style={{width:"100%"}}  color="inherit">$20 + $3.95 sign-up fee Active & Veteran Military</Typography>
              <Typography variant="subtitle1" style={{width:"100%"}}  color="inherit">$35 + $4.95 sign-up fee</Typography>
              <br/>
              <Button variant="contained" target="_blank" rel="noopener noreferrer" href="https://racepenguin.com/events/field-of-heroes-5k/">Sign Up Here</Button>
              <br/>
              <Typography style={{width:"100%"}} variant="h6" color="inherit">Pre-race packet pick up:</Typography>
              <Typography style={{width:"100%"}} variant="subtitle2" color="inherit">May 24th: 11:00am - 7:00pm at Fleet Feet Lewis Center, 1270 E. Powell</Typography>
              <Typography style={{width:"100%"}} variant="subtitle2" color="inherit">May 25th: 10:00am - 6:00pm at Fleet Feet Lewis Center, 1270 E. Powell</Typography>
              <br/>
              <Typography variant="subtitle2" color="inherit">*The Westerville Sunrise Rotary Foundation is a 501(c)3 charitable organization, and therefore a portion of your registration may be tax deductible. The estimated value of goods provided is $12.00.</Typography>
            </Grid>
            <Grid item md={1}/>
          </Grid>
          <div className="divider-no-margin"/>
          <FoHFoot/>
        </div>
      </div>
    );
  }
}

export default Fivek;