import React from 'react';

export class Confirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: props.getStore().firstname,
      lastname: props.getStore().lastname,
      organization: props.getStore().organization,
      address: props.getStore().address,
      city: props.getStore().city,
      state: props.getStore().state,
      zip: props.getStore().zip,
      phone: props.getStore().phone,
      email: props.getStore().email,
      behalf: props.getStore().behalf,
      notes:props.getStore().notes,
      flags:props.getStore().flags,
    }
  }

  render() {
    let {flags} = this.state
    let pickup = flags.filter(function(flag){return parseInt(flag.shiptype)===0}).length
    let dedicate = flags.filter(function(flag){return parseInt(flag.shiptype)===1}).length
    let donate = flags.filter(function(flag){return parseInt(flag.shiptype)===2}).length
    let myship = flags.filter(function(flag){return parseInt(flag.shiptype)===3}).length
    let ship = flags.filter(function(flag){return parseInt(flag.shiptype)===4}).length

    let extship = 0;

    var uniqship = {}
    flags.map((val, idx)=>{
      if(parseInt(flags[idx].shiptype)===4){
        if(flags[idx].flagaddress in uniqship){
          uniqship[flags[idx].flagaddress] = uniqship[flags[idx].flagaddress] + 1
        }else{
          uniqship[flags[idx].flagaddress] = 1
        }
      }
      return "";
    })

    for(var key in uniqship){
      var count = uniqship[key]
      extship = extship + (Math.ceil(count/2)*7.5)
    }

    function addZeroes( num2 ) {
       var num = Number(num2);
       if (String(num).split(".").length < 2 || String(num).split(".")[1].length<=2 ){
           num = num.toFixed(2);
       }
       return num;
    }

    return (
      <div>
        <p>Thanks for your order: {this.state.firstname}, the breakdown of your order is as follows:</p>
        {  
          pickup>0 &&
            <div className="row">{pickup} Flag(s) ordered for pickup at the end of the field for a total of ${addZeroes(pickup*35)}</div>
        }
        {  
          dedicate>0 &&
            <div className="row">{dedicate} Flag(s) dedicated to a hero which will return to our stock for a total of ${addZeroes(dedicate*20)}</div>
        }
        {  
          donate>0 &&
            <div className="row">{donate} Flag(s) donated to Honor Flight for a total of ${addZeroes(donate*35)}</div>
        }
        {  
          myship>0 &&
            <div className="row">{myship} Flag(s) Shipped to your mailing address for a total of ${addZeroes(myship*35)} with an additional ${addZeroes(Math.ceil(myship/2) * 7.5)} shipping</div>
        }
        {  
          ship>0 &&
            <div className="row">{ship} Flag(s) Shipped to a secondary mailing address for a total of ${addZeroes(ship*35)} with an additional ${addZeroes(extship)} shipping</div>
        }
      </div>
    )
  }
}

export default Confirm;