import React from 'react';
import States from "./States.js";
import Grid from '@material-ui/core/Grid';

export class Person extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: props.getStore().firstname,
      lastname: props.getStore().lastname,
      organization: props.getStore().organization,
      address: props.getStore().address,
      city: props.getStore().city,
      state: props.getStore().state,
      zip: props.getStore().zip,
      phone: props.getStore().phone,
      email: props.getStore().email,
      notes:props.getStore().notes,
      flags:props.getStore().flags,
    }
    this.handleFirstNameChanged = this.handleFirstNameChanged.bind(this);
    this.handleLastNameChanged = this.handleLastNameChanged.bind(this);
    this.handleorganizationChanged = this.handleorganizationChanged.bind(this);
    this.handleaddressChanged = this.handleaddressChanged.bind(this);
    this.handlecityChanged = this.handlecityChanged.bind(this);
    this.handlestateChanged = this.handlestateChanged.bind(this);
    this.handlezipChanged = this.handlezipChanged.bind(this);
    this.handlephoneChanged = this.handlephoneChanged.bind(this);
    this.handleemailChanged = this.handleemailChanged.bind(this);
    this.handlenotesChanged = this.handlenotesChanged.bind(this);
  }

  handleFirstNameChanged(event){
    this.props.updateStore({
      firstname: event.target.value
    });
    this.setState({firstname: event.target.value})
  }

  handleLastNameChanged(event){
    this.props.updateStore({
      lastname: event.target.value
    })
    this.setState({lastname: event.target.value})
  }

  handleorganizationChanged(event){
    this.props.updateStore({
      organization: event.target.value
    })
    this.setState({organization: event.target.value})
  }

  handleaddressChanged(event){
    this.props.updateStore({
      address: event.target.value
    })
    this.setState({address: event.target.value})
  }

  handlecityChanged(event){
    this.props.updateStore({
      city: event.target.value
    })
    this.setState({city: event.target.value})
  }

  handlestateChanged(event){
    this.props.updateStore({
      state: event.target.value
    })
    this.setState({state: event.target.value})
  }

  handlezipChanged(event){
    this.props.updateStore({
      zip: event.target.value
    })
    this.setState({zip: event.target.value})
  }

  handlephoneChanged(event){
    var phone = ""+event.target.value.replace(/[^\d]/g,"");
    if(/^[0-9]*$/.test(phone)||phone===""){
      if(phone.length>10){
        phone = "+"+phone.substring(0,phone.length-10)+" ("+phone.substring(phone.length-10,phone.length-7)+")-"+phone.substring(phone.length-7,phone.length-4)+"-"+phone.substring(phone.length-4)
      }else if(phone.length<=3){
      }else if(phone.length<=7){
        phone = phone.substring(0,3)+"-"+phone.substring(3)
      }else if(phone.length<=10){
        phone = "("+phone.substring(0,3)+")-"+phone.substring(3,6)+"-"+phone.substring(6)
      }

      this.props.updateStore({
        phone: phone
      })
      this.setState({phone: phone})
    }
  }

  handleemailChanged(event){
    this.props.updateStore({
      email: event.target.value
    })
    this.setState({email: event.target.value})
  }

  handlenotesChanged(event){
    this.props.updateStore({
      notes: event.target.value
    })
    this.setState({notes: event.target.value})
  }

  isValidated(){
    let {flags} = this.state
    flags.map((val, idx)=>{
      if(flags[idx].behalf===null){
        flags[idx].behalf=this.state.firstname + " " + this.state.lastname;
      }
      return "";
    })

    var any = true;
    var firstname = this.refs.firstname;
    var firstnameerr = this.refs.firstnameerr;
    var lastname = this.refs.lastname;
    var lastnameerr = this.refs.lastnameerr;
    var address = this.refs.address;
    var addresserr = this.refs.addresserr;
    var city = this.refs.city;
    var cityerr = this.refs.cityerr;
    var zip = this.refs.zip;
    var ziperr = this.refs.ziperr;
    var phone = this.refs.phone;
    var phoneerr = this.refs.phoneerr;
    var email = this.refs.email;
    var emailerr = this.refs.emailerr;
    var state = document.getElementById("state");
    var stateerr = this.refs.stateerr;

    if(this.state.firstname === ""){
      //First Name is empty
      firstnameerr.className = "err"
      firstname.className = "purchaseInput form-control invalid-border";
      any = false;
    }else{
      firstnameerr.className = "noErr"
      firstname.className = "purchaseInput form-control";
    }
    if(this.state.lastname === ""){
      //Last name is empty
      lastnameerr.className = "err"
      lastname.className = "purchaseInput form-control invalid-border";
      any = false;
    }else{
      lastnameerr.className = "noErr"
      lastname.className = "purchaseInput form-control";
    }
    if(document.getElementById("state").value === ""){
      //State not picked
      stateerr.className = "err"
      state.className = "purchaseInput form-control invalid-border";
      any = false;
    }else{
      stateerr.className = "noErr"
      state.className = "purchaseInput form-control";
    }
    if(this.state.address === ""){
      //Address is empty
      addresserr.className = "err"
      address.className = "purchaseInput form-control invalid-border";
      any = false;
    }else{
      addresserr.className = "noErr"
      address.className = "purchaseInput form-control";
    }
    if(this.state.city === ""){
      //City is empty
      cityerr.className = "err"
      city.className = "purchaseInput form-control invalid-border";
      any = false;
    }else{
      cityerr.className = "noErr"
      city.className = "purchaseInput form-control";
    }
    if(!/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(this.state.zip)){
      //Not a real zip code
      ziperr.className = "err"
      zip.className = "purchaseInput form-control invalid-border";
      any = false;
    }else{
      ziperr.className = "noErr"
      zip.className = "purchaseInput form-control";
    }
    if(this.state.phone === ""){
      //Empty phone
      phoneerr.className = "err"
      phone.className = "purchaseInput form-control invalid-border";
      any = false;
    }else{
      phoneerr.className = "noErr"
      phone.className = "purchaseInput form-control";
    }
    if(!/^\S+@\S+\.\S+$/.test(this.state.email)){
      //Naive email validation
      emailerr.className = "err"
      email.className = "purchaseInput form-control invalid-border";
      any = false;
    }else{
      emailerr.className = "noErr"
      email.className = "purchaseInput form-control";
    }
    if(any === false){
      return false
    }
    return true;
/**/
  }

  componentWillUnmount(){}

  render() {
    return (
      <Grid container spacing={8} justify="center">
        <Grid container spacing={8}>
          <Grid item md={6} sm={6}  xs={12}>
            <label>First Name</label><div className="noErr" ref="firstnameerr"> - You must enter a first name</div>
            <input className="purchaseInput form-control" type="text" id="firstname" ref="firstname" onChange={this.handleFirstNameChanged} value={this.state.firstname} required="required"/>
          </Grid>
          <Grid item md={6} sm={6}  xs={12}>
            <label>Last Name</label><div className="noErr" ref="lastnameerr"> - You must enter a last name</div>
            <input className="purchaseInput form-control" type="text" id="lastname" ref="lastname" onChange={this.handleLastNameChanged} value={this.state.lastname} required="required"/>
          </Grid>
        </Grid>

        <Grid item md={12} sm={12}  xs={12}>
          <label>Organization</label>
          <input className="purchaseInput form-control" type="text" id="organization" ref="organization" onChange={this.handleorganizationChanged} value={this.state.organization}/>
        </Grid>

        <Grid item md={12} sm={12}  xs={12}>
          <label>Address</label><div className="noErr" ref="addresserr"> - You must enter an address</div>
          <input className="purchaseInput form-control" type="text" id="address" ref="address" onChange={this.handleaddressChanged} value={this.state.address} required="required"/>
        </Grid>

        <Grid container spacing={8}>
          <Grid item md={6} sm={12}  xs={12}>
            <label>City</label><div className="noErr" ref="cityerr"> - You must enter a City</div>
            <input className="purchaseInput form-control" type="text" id="city" ref="city" onChange={this.handlecityChanged} value={this.state.city} required="required"/>
          </Grid>

          <Grid item md={3} sm={6}  xs={12}>
            <label>State</label><div className="noErr" ref="stateerr"> - Required</div>
            <States getStore={() => (this.props.getStore())} updateStore={(u) => {this.props.updateStore(u)}} />
          </Grid>

          <Grid item md={3} sm={6}  xs={12}>
            <label>Zip</label><div className="noErr" ref="ziperr"> - Required</div>
            <input className="purchaseInput form-control" type="text" id="zip" ref="zip" onChange={this.handlezipChanged} value={this.state.zip} required="required"/>
          </Grid>
        </Grid>

        <Grid container spacing={8}>
          <Grid item md={6} sm={6}  xs={12}>
            <label>Phone Number</label><div className="noErr" ref="phoneerr"> - You must enter a Phone Number</div>
            <input className="purchaseInput form-control" type="phone" id="phone" ref="phone" onChange={this.handlephoneChanged} value={this.state.phone} required="required"/>
          </Grid>
          <Grid item md={6} sm={6}  xs={12}>
            <label>Email Address</label><div className="noErr" ref="emailerr"> - You must enter an Email</div>
            <input className="purchaseInput form-control" type="email" id="email" ref="email" onChange={this.handleemailChanged} value={this.state.email} required="required"/>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default Person;