import React from 'react';
import axios from "axios";

export class FlagStates extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			id:props.getID(),
			flags:props.getStore().flags,
			states:[]
		}
		this.changestates = this.changestates.bind(this);
	}

	componentDidMount(){
		axios.get("https://fohapi.groupeleven.com/states/")
			.then(response => {
				const states = response.data
				this.setState({states})
			})
		this.props.updateStore({
      		flags:this.state.flags
    	})
	}

	changestates(event){
	    let idx = this.state.id
	    const newFlags = this.props.getStore().flags.map((flag, sidx) =>
	    {
	      if(idx !== "flagstate-"+sidx) return flag;
	      return {...flag,flagstate:event.target.value}
	    })
	    this.props.updateStore({
	      flags: newFlags
	    })
	    this.props.fakeupdater()
	}

	render(){
		let {states} = this.state
		let singlestate = this.props.getValue()
		return (
			<select id={this.state.id} ref={this.state.id} onChange={this.changestates} value={singlestate} className="purchaseInput form-control">
			<option key="" value="">Pick a state</option>
			{
				states.map((index)=>
				{
					return(<option key={index["StateAbrev"]} value={index["StateAbrev"]}>{index["State"]}</option>)
				})
			}
			</select>
		)
	}
}

export default FlagStates