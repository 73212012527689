import React from 'react';
import facebook from "./img/socialmedia/facebook.jpg"
import youtube from "./img/socialmedia/youtube.jpg"
import twitter from "./img/socialmedia/twitter.jpg"

export default class FoHFoot extends React.Component {
  render() {
    return (
      <div className="FoH-Foot">
        <p className="FoH-Foot-Text">Field of Heroes &copy;{(new Date().getFullYear())} Presented by the Westerville Sunrise Rotary Club</p>
        <a target="_blank" rel="noopener noreferrer" href="http://www.facebook.com/fieldofheroes">
        	<img src={facebook} alt="Facebook"/>
        </a>
        &nbsp;
        <a target="_blank" rel="noopener noreferrer" href="http://www.youtube.com/user/FieldOfHeroes/videos">
        	<img src={youtube} alt="Youtube"/>
        </a>
        &nbsp;
        <a target="_blank" rel="noopener noreferrer" href="http://twitter.com/FieldOfHeroesOH">
        	<img src={twitter} alt="Twitter"/>
        </a>
        <p className="FoH-Foot-Text">Website developed by GroupEleven</p>
      </div>
    );
  }
}