import React from 'react';
import FoHFoot from './Foot.js'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

export class Schedule extends React.Component {
  render() {
    return (
      <div className="App">
        <div className="App-content">
          <Grid container justify="center" spacing={8}>
          	<Grid item md={12} sm={12} xs={12}>
              <Typography variant="h3" align="center" color="inherit">Schedule of Events</Typography><br/>
          	</Grid>
          	<Grid item md={12} sm={12} xs={12}>
              <Typography variant="h4" align="center" color="inherit">Friday, May 24</Typography><br/>
          	</Grid>

          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>
          	<Grid item md={2} sm={2} xs={4}>
                <Typography variant="subtitle1" align="center" color="inherit">6:00 P.M.</Typography>
          	</Grid>
          	<Grid item md={4} sm={6} xs={8}>
                <Typography variant="subtitle1" align="center" color="inherit">Bagpipe Serenade</Typography>
          	</Grid>
          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>

          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>
          	<Grid item md={2} sm={2} xs={4}>
                <Typography variant="subtitle1" align="center" color="inherit">8:30 P.M.</Typography>
          	</Grid>
          	<Grid item md={4} sm={6} xs={8}>
                <Typography variant="subtitle1" align="center" color="inherit">"Taps" | Field Illuminated Until Dawn</Typography>
          	</Grid>
          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>

          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>
          	<Grid item md={2} sm={2} xs={4}>
                <Typography variant="subtitle1" align="center" color="inherit">9:00 P.M.</Typography>
          	</Grid>
          	<Grid item md={4} sm={6} xs={8}>
                <Typography variant="subtitle1" align="center" color="inherit">Flag and Challenge Coin sales close for the night</Typography>
          	</Grid>
          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>

          	<Grid item md={12} sm={12} xs={12} className="mt-3">
              <Typography variant="h4" align="center" color="inherit">Saturday, May 25</Typography><br/>
          	</Grid>

          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>
          	<Grid item md={2} sm={2} xs={4}>
                <Typography variant="subtitle1" align="center" color="inherit">12:00 P.M.</Typography>
          	</Grid>
          	<Grid item md={4} sm={6} xs={8}>
                <Typography variant="subtitle1" align="center" color="inherit">Reading of Names on Dedicated Flags</Typography>
          	</Grid>
          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>

            <Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>
            <Grid item md={2} sm={2} xs={4}>
                <Typography variant="subtitle1" align="center" color="inherit">1:00 P.M. to 4:00 P.M.</Typography>
            </Grid>
            <Grid item md={4} sm={6} xs={8}>
                <Typography variant="subtitle1" align="center" color="inherit">Tours of the Hanby House **Located at: Hanby House Historic Site, 160 W. Main Street</Typography>
            </Grid>
            <Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>

          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>
          	<Grid item md={2} sm={2} xs={4}>
                <Typography variant="subtitle1" align="center" color="inherit">7:00 P.M.</Typography>
          	</Grid>
          	<Grid item md={4} sm={6} xs={8}>
                <Typography variant="subtitle1" align="center" color="inherit">Free Concert featuring the Westerville Community Bands</Typography>
          	</Grid>
          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>

          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>
          	<Grid item md={2} sm={2} xs={4}>
                <Typography variant="subtitle1" align="center" color="inherit">8:30 P.M.</Typography>
          	</Grid>
          	<Grid item md={4} sm={6} xs={8}>
                <Typography variant="subtitle1" align="center" color="inherit">Echo "Taps" | Field Illuminated Until Dawn</Typography>
          	</Grid>
          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>

          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>
          	<Grid item md={2} sm={2} xs={4}>
                <Typography variant="subtitle1" align="center" color="inherit">9:00 P.M.</Typography>
          	</Grid>
          	<Grid item md={4} sm={6} xs={8}>
                <Typography variant="subtitle1" align="center" color="inherit">Flag and Challenge Coin sales close for the night</Typography>
          	</Grid>
          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>

          	<Grid item md={12} sm={12} xs={12} className="mt-3">
              <Typography variant="h4" align="center" color="inherit">Sunday, May 26</Typography><br/>
          	</Grid>

          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>
          	<Grid item md={2} sm={2} xs={4}>
                <Typography variant="subtitle1" align="center" color="inherit">8:00 A.M.</Typography>
          	</Grid>
          	<Grid item md={4} sm={6} xs={8}>
                <Typography variant="subtitle1" align="center" color="inherit">Field of Heroes 5K Run/Walk Presented by Ohio Health</Typography>
          	</Grid>
          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>

          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>
          	<Grid item md={2} sm={2} xs={4}>
                <Typography variant="subtitle1" align="center" color="inherit">12:00 P.M.</Typography>
          	</Grid>
          	<Grid item md={4} sm={6} xs={8}>
                <Typography variant="subtitle1" align="center" color="inherit">Reading of Names on Dedicated Flags</Typography>
          	</Grid>
          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>

          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>
          	<Grid item md={2} sm={2} xs={4}>
                <Typography variant="subtitle1" align="center" color="inherit">3:45 P.M.</Typography>
          	</Grid>
          	<Grid item md={4} sm={6} xs={8}>
                <Typography variant="subtitle1" align="center" color="inherit">Bagpipe Serenade prelude to Flag Retirement Ceremony</Typography>
          	</Grid>
          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>

          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>
          	<Grid item md={2} sm={2} xs={4}>
                <Typography variant="subtitle1" align="center" color="inherit">4:00 P.M.</Typography>
          	</Grid>
          	<Grid item md={4} sm={6} xs={8}>
                <Typography variant="subtitle1" align="center" color="inherit">Flag Retirement Ceremony led by Troop 64</Typography>
          	</Grid>
          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>

          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>
          	<Grid item md={2} sm={2} xs={4}>
                <Typography variant="subtitle1" align="center" color="inherit">6:00 P.M.</Typography>
          	</Grid>
          	<Grid item md={4} sm={6} xs={8}>
                <Typography variant="subtitle1" align="center" color="inherit">Ohio Village Muffins vs Westerville Sunrise Rotary & Co.</Typography>
          	</Grid>
          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>

          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>
          	<Grid item md={2} sm={2} xs={4}>
                <Typography variant="subtitle1" align="center" color="inherit">8:30 P.M.</Typography>
          	</Grid>
          	<Grid item md={4} sm={6} xs={8}>
                <Typography variant="subtitle1" align="center" color="inherit">"Taps" | Field Illuminated Until Dawn</Typography>
          	</Grid>
          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>

          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>
          	<Grid item md={2} sm={2} xs={4}>
                <Typography variant="subtitle1" align="center" color="inherit">9:00 P.M.</Typography>
          	</Grid>
          	<Grid item md={4} sm={6} xs={8}>
                <Typography variant="subtitle1" align="center" color="inherit">Flag and Challenge Coin sales close for the night</Typography>
          	</Grid>
          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>

          	<Grid item md={12} sm={12} xs={12} className="mt-3">
              <Typography variant="h4" align="center" color="inherit">Monday, May 27</Typography><br/>
          	</Grid>

          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>
          	<Grid item md={2} sm={2} xs={4}>
                <Typography variant="subtitle1" align="center" color="inherit">10:30 A.M.</Typography>
          	</Grid>
          	<Grid item md={4} sm={6} xs={8}>
                <Typography variant="subtitle1" align="center" color="inherit">Flag Sales Close for Event</Typography>
          	</Grid>
          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>

            <Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>
            <Grid item md={2} sm={2} xs={4}>
                <Typography variant="subtitle1" align="center" color="inherit">11:30 A.M.</Typography>
            </Grid>
            <Grid item md={4} sm={6} xs={8}>
                <Typography variant="subtitle1" align="center" color="inherit">Reading of Names on Dedicated Flags</Typography>
            </Grid>
            <Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>

            <Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>
            <Grid item md={2} sm={2} xs={4}>
                <Typography variant="subtitle1" align="center" color="inherit">12:15 P.M.</Typography>
            </Grid>
            <Grid item md={4} sm={6} xs={8}>
                <Typography variant="subtitle1" align="center" color="inherit">Memorial Day Ceremony</Typography>
            </Grid>
            <Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>

            <Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>
            <Grid item md={2} sm={2} xs={4}>
                <Typography variant="subtitle1" align="center" color="inherit">12:15 P.M.</Typography>
            </Grid>
            <Grid item md={4} sm={6} xs={8}>
                <Typography variant="subtitle1" align="center" color="inherit">Flyover of the Commemorative Air Force Buckey Wing</Typography>
            </Grid>
            <Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>

          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>
          	<Grid item md={2} sm={2} xs={4}>
                <Typography variant="subtitle1" align="center" color="inherit">1:00 - 3:30 P.M.</Typography>
          	</Grid>
          	<Grid item md={4} sm={6} xs={8}>
                <Typography variant="subtitle1" align="center" color="inherit">Flag Pick-Up</Typography>
          	</Grid>
          	<Hidden only="xs"><Grid item md={3} sm={2}></Grid></Hidden>
          </Grid>
          <div className="divider-no-margin"/>
          <FoHFoot/>
        </div>
      </div>
    );
  }
}

export default Schedule;